<template>
  <el-card shadow="never">
    <el-form inline>
      <el-form-item>
        <el-input v-model="code" placeholder="借出单号" clearable style="width: 210px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="remark" placeholder="备注" clearable style="width: 240px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="deleteOrder" :loading="loading">强行关闭</el-button>
        <el-button type="primary" @click="reopenOrder" :loading="loading">打开</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "DeleteLendOrder",
  data() {
    return {
      code: '',
      remark: '',
      loading: false
    }
  },
  methods: {
    reopenOrder() {
      if (!this.code) {
        return this.$message.error('借出单号不能为空！');
      }
      if (!this.remark) {
        return this.$message.error('备注不能为空！');
      }

      this.loading = true;
      this.$axios.post('lendOrder/reopen', {
        code: this.code,
        remark: this.remark
      }).then(response => {
        this.loading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        this.code = ''
        this.remark = ''
        return this.$message.success('操作成功');

      }, error => {
        this.loading = false;
        return this.$message.error('操作失败，' + error.message);
      });

    },
    deleteOrder() {
      if (!this.code) {
        return this.$message.error('借出单号不能为空！');
      }
      if (!this.remark) {
        return this.$message.error('备注不能为空！');
      }
      this.$confirm('该操作将强行修改借出单及相关物品数据为关闭状态，是否确定删除？', '警告', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.loading = true;
        this.$axios.post('lendOrder/forceClose', {
          code: this.code,
          remark: this.remark
        }).then(response => {
          this.loading = false;
          let res = response.data;
          if (res.code !== 0) {
            return this.$message.error(res.message);
          }

          this.code = ''
          this.remark = ''
          return this.$message.success('操作成功');

        }, error => {
          this.loading = false;
          return this.$message.error('操作失败，' + error.message);
        });
      })

    }
  }
}
</script>

<style scoped>

</style>